import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

const Admin = () => import('@/pages/admin/Index.vue');
const Login = () => import('@/pages/auth/Index.vue');
const AppList = () => import('@/pages/admin/appList/AppList.vue');
const AppDetail = () => import('@/pages/admin/appDetail/AppDetail.vue');
const appPreview = () => import('@/pages/appPreview/AppPreview.vue');
const UserManagement = () => import('@/pages/admin/userManagement/UserManagement.vue');

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Admin',
    component: Admin,
    redirect: '/appList',
    children: [
      {
        path: 'appList',
        name: 'appList',
        component: AppList,
      },
      {
        path: 'appDetail',
        name: 'appDetail',
        component: AppDetail,
      },
      {
        path: 'userManagement',
        name: 'userManagement',
        component: UserManagement,
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/:id',
    name: 'appPreview',
    component: appPreview,
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
